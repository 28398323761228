<template>
    <div>
        <headTitle/>
        <img class="mobile-example-headImg" src="../../assets/image/mobile/04-1数据湖仓技术.png"/>
        <div class="mobile-head-explain" style="margin-top:-30rem">
            <div class="mobile-head-explain-title">数据湖仓技术</div>
            <div class="mobile-head-explain-content">致力于帮助企业构建数据湖，推动企业数字化转型</div>
        </div>
        <img class="service-img1" src="../../assets/image/mobile/04-2.png"/>
        <div style="margin-top: 1.6rem;">
            <el-collapse-transition>
                <div v-if="true">
                    <div class="description-box"> 比孚科技作为Amazon高级咨询合作伙伴，致力于帮助企业构建数据湖，实现核心流程数字化、自助式商业智能（Business Intelligence)、
                        BI移动化等服务，推动企业数字化转型。</div>
                </div>
            </el-collapse-transition>
        </div>
        <div class="mobile-head-catalogue" style="margin-top:5.7rem">
            <div :class="{'title1':true,'ischecked':checkIndex==1} " @click="(checkIndex=1)"><span>数据湖服务</span></div>
            <div :class="{'title1':true,'ischecked':checkIndex==2} " @click="(checkIndex=2)"><span>解决方案</span></div>
            <div :class="{'title1':true,'ischecked':checkIndex==3} " @click="(checkIndex=3)"><span>项目实施方法论</span></div>
        </div>
        <div v-if="(checkIndex==1)" class="mobile-content-box1">
            <div class="data-lake-box">
                <div class="card">
                    <img src="../../assets/image/technicalService/04-1-1接入数据.svg"/>
                    <div class="lake-box1">
                        <div class="title1">接入数据源类型</div>
                        <div class="title2">结构化数据、非结构化数据、企业内部数据、企业外部数据、批次数据、实时数据等；
                            包含但不仅限于以下数据源：ERP(SAP/Oracle ERP)、CRM(Salesforce/Microsoft Dynamics 365)、APP(Weixin/TalkingData)、图像语音。</div>
                    </div>
                </div>
                <div class="card">
                    <img src="../../assets/image/technicalService/04-1-1接入方式.svg"/>
                    <div class="lake-box1">
                        <div class="title1">接入方式</div>
                        <div class="title2">可通过互联网公开网络、虚拟专用网络(VPN)、Amazon直连专线(Direct Connect)等方式完成云上和线下的网络连通。
                            使用数据库迁移服务（DMS）、Lambda、Kinesis、CLI等组件完成数据上云；使用Talend、Informatica、Kettle、Data Pipeline等第三方产品完成数据上云。</div>
                    </div>
                </div>
                <div class="card">
                    <img src="../../assets/image/technicalService/04-1-1批处理.svg"/>
                    <div class="lake-box1">
                        <div class="title1">批处理</div>
                        <div class="title2">利用Redshift完成结构化数据的SQL处理；利用EMR Spark等完成结构化、非结构化的批数据处理；<br/>
                            利用EC2搭建第三方处理平台Airflow、MapR、Hadoop、Informatica等完成结构化、非结构化的批数据处理。</div>
                    </div>
                </div>
                <div class="card">
                    <img src="../../assets/image/technicalService/04-1-1实时处理.svg"/>
                    <div class="lake-box1">
                        <div class="title1">实时处理</div>
                        <div class="title2">利用Kinesis、EMR Spark Streaming、Lambda、SQS等Amazon服务完成实时数据处理；<br/>
                            利用搭建在EC2的第三方处理平台Kafka、Rabbit MQ等完成实时数据处理。</div>
                    </div>
                </div>
                <div class="card">
                    <img src="../../assets/image/technicalService/04-1-1机器学习.svg"/>
                    <div class="lake-box1">
                        <div class="title1">机器学习/人工智能</div>
                        <div class="title2">利用SageMaker、Rekognition、Lex等Amazon服务完成机器学习/人工智能；<br/>
                            利用搭建在EC2的第三方处理平台TensorFlow、Keras、MXNet、PyTorch等完成机器学习/人工智能。</div>
                    </div>
                </div>
                <div class="card">
                    <img src="../../assets/image/technicalService/04-1-1数据应用.svg"/>
                    <div class="lake-box1">
                        <div class="title1">数据应用服务</div>
                        <div class="title2">使用QuickSight、Tableau、PowerBI、FineReport、FineBI等展示工具对接Athena、ElasticSearch、Redshift、EMR、Kylin等组件完成数据应用服务的提供。</div>
                    </div>
                </div>
                <div class="card">
                    <img src="../../assets/image/technicalService/04-1-1平台安全.svg"/>
                    <div class="lake-box1">
                        <div class="title1">平台安全及监控</div>
                        <div class="title2">使用VPC、IAM、KMS、CloudTrial、CloudWatch等组件完成平台安全及监控。</div>
                    </div>
                </div>
            </div>
            <div class="ware-title" style="margin-top:4.7rem;text-align: center;margin-bottom: 3rem;">数据湖常用服务</div>
            <img class="ware-img" src="../../assets/image/technicalService/04-1-1常用服务.png"/>
        </div>
        <div v-else-if="(checkIndex==2)" class="mobile-content-box1">
            <div class="ware-title" style="margin-top:4.7rem;text-align: center;margin-bottom: 3rem;">比孚大数据湖解决方案</div>
            <img class="ware-img" src="../../assets/image/technicalService/04-1-1解决方案.png"/>
        </div>
        <div v-else-if="(checkIndex==3)" class="mobile-content-box1">
            <div class="custom-card">
                <div class="card-img"><img src="../../assets/image/technicalService/04-1-1Amazon服务.svg"/></div>
                <div class="title3-1">Amazon服务</div>
                <div class="title3-2">Amazon供应的集成服务套件可提供快速而轻松地构建和
                            管理数据湖以进行分析所需的每个要素。Amazon支持的数据湖可以采用传统数
                            据孤岛和数据仓库无法胜任的方法，处理组合不同类型的数据和分析方法以获
                            得更深层次的见解所必需的扩展性、敏捷性和灵活性。Amazon为客户提供最广
                            泛的分析阵列和机器学习服务，以轻松访问所有相关数据，而不会妨碍安全性
                            或监管。</div>
            </div>
            <div class="custom-card" style="margin-top:2rem">
                <div class="card-img"><img src="../../assets/image/technicalService/04-1-1技术能力.svg"/></div>
                <div class="title3-1">技术能力</div>
                <div class="title3-2">比孚作为Amazon Partner Network(APN) 高级 (Adva
                            nced Tier) 咨询合作伙伴，致力以云技术为企业数字化转型赋能。拥有诸多
                            通过Amazon认证的技术人员，对Amazon服务的特点有着深刻的理解。</div>
            </div>
            <div class="custom-card" style="margin-top:2rem;margin-bottom: 5rem;">
                <div class="card-img"><img src="../../assets/image/technicalService/04-1-1行业经验.svg"/></div>
                <div class="title3-1">丰富的行业经验</div>
                <div class="title3-2">比孚团队成员来自多家国际知名咨询和信息技术服务公司
                            ，在管理与技术领域拥有丰富经验。通过给细分行业龙头企业的服务，不断积累
                            行业经验。框架化的业务成果积累，可以帮助团队成员快速了解行业。团队成员
                            整体丰富的行业经验，可以帮助客户快速找到技术和业务平衡点，帮助客户进行
                            业务基于数据湖上的落地。</div>
            </div>
        </div>
        <mobileFoot/>
    </div>
</template>

<script>
import headTitle from '../../components/mobile/head-title.vue'
import mobileFoot from '../../components/mobile/mobile-foot.vue'
import mobileCarousel from '../../components/mobile/mobile-carousel.vue'
import Swiper from "swiper";
export default {
    name: 'BizfocusIndexMobileIndex',
    components:{headTitle,mobileCarousel,mobileFoot},
    data() {
        return {
            checkIndex: 1,
            serviceIndex:1,
            showDescription:true,
            box2ImageList:[
                {
                    index:0,
                    urlImg:require("../../assets/image/index/01-15GF整合数据分析系统.png"),
                    title:'5GF整合数据分析系统',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-5GF'
                },
                {
                    index:1,
                    urlImg:require("../../assets/image/index/01-1数字化产线.png"),
                    title:'数字化产线', //右转是0
                    content:'打造透明、高效、稳定的数字化生产管理平台',
                    route:'/selected-line'
                },
                {
                    index:2,
                    urlImg:require("../../assets/image/index/01-1市场渗透率分析.png"),
                    title:'市场渗透率分析',
                    content:'全球某化妆品巨头',
                    route:'/selected-marykay'
                },
                {
                    index:3,
                    urlImg:require("../../assets/image/index/01-1智能仓储.png"),
                    title:'智能仓储',
                    content:'布局原料赋码，助力品质生产',
                    route:'/selected-warehousing'
                },
                {
                    index:4,
                    urlImg:require("../../assets/image/index/01-1Dashboard数据实施.png"),
                    title:'Dashboard数据实施',
                    content:'全球某知名乳制品公司',
                    route:'/selected-dashboard'
                },
                {
                    index:5,
                    urlImg:require("../../assets/image/index/01-1商情诊断管理分析.png"),
                    title:'商情诊断管理分析',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-business'
                },
                {
                    index:6,
                    urlImg:require("../../assets/image/index/01-1iDAS整合数据分析系统.png"),
                    title:'iDAS整合数据分析系统', //左转是0
                    content:'韩国某知名化妆品集团',
                    route:'/selected-iDAS'
                },

            ],
            boxService:[
                {
                    index:0,
                    urlImg:require("../../assets/image/technicalService/04-3-1需求调研.svg"),
                    title:'需求调研  原型设计',
                    content:'通过封闭式3天集中沟通，头脑风暴，形成图文并茂的需求文档。',
                },
                {
                    index:1,
                    urlImg:require("../../assets/image/technicalService/04-3-1蓝图设计.svg"),
                    title:'蓝图设计',
                    content:'蓝图设计包括功能设计,流程设计,权限设计，视觉设计，技术设计等。在需求关键点上持续沟通和确认。',
                },
                {
                    index:2,
                    urlImg:require("../../assets/image/technicalService/04-3-1系统演示.svg"),
                    title:'系统演示',
                    content:'在每个迭代开发结束后,通过操作演示,从平面上升到立体层面，与用户沟通和再次确认需求。',
                },
                {
                    index:3,
                    urlImg:require("../../assets/image/technicalService/04-3-1功能确认.svg"),
                    title:'最终功能确认',
                    content:'在这个阶段，通常不再收集新的需求，重点在于查漏补缺。通过关键用户上手操作体验。对项目范围内所有功能进行最后一次确认和固化。针对提出的必要的优化建议，微调系统进行完善。',
                },
            ],
        };
    },

    mounted() {
        
    },

    methods: {
        jumpMobileRoute(route){
            this.$router.push(route)
            console.log(route)
        }
    },
};
</script>

<style lang="less" scoped>
.description{
    display: flex;
    width: 66.5rem;
    margin: auto;
    margin-top: 6rem;
    .title1{
        font-size: 2.8rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #11A84F;
        line-height: 4.2rem;
    }
    .title2{
        font-size: 2.8rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #11A84F;
        line-height: 4.2rem;
        margin-left: 70%;
    }
}
.description-box{
    max-width: 66.5rem;
    margin: auto;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 5rem;
}
.mobile-head-catalogue{
    width: 100%;
    height: 9rem;
    display: flex;
    background: #F8F8F8;
    .title1{
        width: 25rem;
        height: 9rem;
        display: flex;
        text-align: center;
        align-items: center;
        span{
            font-size: 2.8rem;
            font-family: 'CN_Medium';
            font-weight: 500;
            color: #4F4F4F;
            line-height: 2rem;
            margin: auto;
        }
    }
    .ischecked{
        background: #EDEDED;
        border-bottom: 2px solid #11A84F;
    }
}
.mobile-content-box1{
    width: 66rem;
    margin: auto;
    margin-top: 3rem;
}
.ware-content1{
    max-width: 66rem;
    // height: 10.6rem;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 4rem;
}
.ware-title{
    font-size: 2.8rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #000000;
    line-height: 4.2rem;
}
.ware-img{
    width: 66.5rem;
    height: auto;
    margin-bottom: 5rem;
}
.ware-img2{
    width: 66.5rem;
    height: 28rem;
}
.service-img1{
    width: 65rem;
    height: auto;
    margin: auto;
    margin-top: 3rem;
    display: flex;
    text-align: center;
}
.custom-card{
    width: 100%;
    height: 55rem;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px 0px rgba(236,236,236,0.6);
    padding-bottom: 0rem;
    margin: auto;
    .card-img{
        padding-top: 4.8rem;
       img{
        width: 6.4rem;
        height: 6.4rem;
        display: flex;
        margin: auto;
    } 
    }
    
    .title3-1{
        height: 2rem;
        font-size: 2.8rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #000000;
        line-height: 3rem;
        text-align: center;

        margin-top: 2rem;
    }
    .title3-2{
        max-width: 60rem;
        height: 7.5rem;
        font-size: 2.5rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #4F4F4F;
        line-height: 4rem;
        text-align: justify;
        margin: auto;

        margin-top: 3rem;
        padding-bottom: 3rem;

    }
}
.custom-img1{
    width: 100%;
    height: 16.6rem;

    margin-top: 1rem;
}
.custom-img2{
    width: 67.8rem;
    height: 15.5rem;
    display: flex;
    text-align: center;
    margin-top: 2rem;
}
.read-more{
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #11A84F;
    line-height: 3rem;
    text-align: center;
    margin-bottom:5rem;
    margin-top: 3rem;
}
.data-lake-box{
    width: 66.5rem;
    margin: auto;
    margin-bottom: 6rem;
    .card{
        display: flex;
        // height: 15rem;
        margin-top: 7rem;
        // border: 1px solid;
        img{
            width: 10rem;
            height: 10rem;
            margin-top: 2rem;
        }
        .lake-box1{
            margin-left: 2rem;
            text-align: justify;
            .title1{
                height: 2.8rem;
                font-size: 2.8rem;
                font-family: 'CN_Regular';
                font-weight: 400;
                color: #333333;
                line-height: 2.8rem;
            }
            .title2{
                // height: 4.8rem;
                font-size: 2.5rem;
                font-family: 'CN_Regular';
                font-weight: 400;
                color: #666666;
                line-height: 2.8rem;
                margin-top: 1rem;
            }
        }
    }
}
</style>